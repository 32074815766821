@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/responsive';
@use '@/styles/resources/z-index' as zIndex;

.page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    @include responsive.media('print') {
        min-height: unset !important;
    }
}

.main {
    flex-grow: 1;
    padding-bottom: 2rem;
}

.load {
    align-items: center;
    background-color: var(--color-white, $color-white);
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: zIndex.$index--header - 1;
}

.innerContent {
    align-items: center;
    display: flex;
    justify-content: center;
}
